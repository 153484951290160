<template>
  <div>
    <v-dialog v-model="notifications.dialog" width="550" scrollable>
      <v-card :loading="notifications.loading">
        <v-card-title class="title primary white--text">
          {{ dialogTitle }}
          <v-spacer></v-spacer>
          <v-btn
            @click="notifications.dialog = false"
            icon
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text id="v-dialog-text" class="pt-5">
          <div
            v-if="notifications.data.length == 0"
            class="text-center"     
          >
            No hay comentarios!
          </div>
          <v-row v-else v-for="(not, index) in notifications.data" :key="not.not_id">
            <v-col :id=" index == notifications.data.length - 1 ? 'last' : '' " sm="2" class="text-center py-1">
              <v-tooltip  v-if=" sameUser(index) " top>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar 
                    v-bind="attrs"
                    v-on="on"    
                    class="elevation-5"  
                    :color="not.usr_color"
                  >
                    <h2 class="font-weight-regular white--text">{{ not.usr_initials }}</h2>
                  </v-avatar>
                </template>
                <span class="text-caption">{{ not.usr_name }}</span>
              </v-tooltip>
            </v-col>
            <v-col sm="10" class="py-1">
              <v-card-text class="pt-0 pl-0">
                <v-sheet
                  class="white--text pa-3"
                  :color="not.usr_color"
                  elevation="5"
                  rounded
                >
                  {{ not.not_text }}
                  <p class="text-end text-caption grey--text text--lighten-3 mt-1 mb-0">{{ convertDate(not.not_date, 1) }}</p>
                </v-sheet>
              </v-card-text>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          v-if="hasNotificationRights"
          class="grey lighten-3 py-4"
        >
          <v-textarea 
            label="Escribe tu mensaje"
            v-model.trim="message"
            class="white elevation-0"
            ref="message"
            rows="2"
            no-resize
            hide-details
            outlined
          >
          </v-textarea>
          <v-btn
            @click="messageAdd"
            class="elevation-0 ml-1"
            color="primary"
            height="66"
            :disabled=" message.length <= 0 "
          >
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-actions>    
          <v-btn
            v-if=" hasAlertRights "
            color="primary"
            text
            @click="updateInstitutionAlert(0)"
          >
            Anular alerta
          </v-btn> 
          <v-spacer></v-spacer>    
          <v-btn
            color="primary"
            text
            @click="loadNotifications(1)"
          >
            Recargar
          </v-btn>  
          <v-btn
            color="primary"
            text
            @click="notifications.dialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { mixinGlobal } from '@/mixins/mixinGlobal'

  export default {
    name: 'notifications',
    mixins: [ mixinGlobal ],
    data: () => ({
      institution: {
        data: []
      },
      notifications:{
        data: [],
        loading: false,
        dialog: false
      },
      insitutionId: null,
      message: ''
    }),
    mounted(){}, 
    computed:{
      ...mapGetters({
        getUserData: 'auth/getUserData'
      }),  
      hasAlertRights(){
        return this.getUserData.usr_role == 'admin' ? true : false
      },
      hasNotificationRights(){
        return ['admin', 'editor'].includes(this.getUserData.usr_role)
      },
      dialogTitle(){
        let length = 45
        if( this.institution.data.length > 0 ){
          if( this.institution.data[0].ins_nombre.length >= length ){
            return this.institution.data[0].ins_nombre.substr(0, length ) + '...'
          }else{
            return this.institution.data[0].ins_nombre
          }
        }else{
          return ''
        }
      }
    },
    methods: {
      loadInstitutionNotifications(insIdParent){
        // for messageAdd and 0 notifications
        this.insitutionId = Number.isInteger(insIdParent) && insIdParent > 0 ? insIdParent : this.insitutionId 

        this.notifications.loading = true

        this.$axios.get('ins_instituciones?fields=ins_id,ins_nombre&ins_id=' + this.insitutionId)
        .then( (respuesta) =>{  
          this.institution.data = respuesta.data.data
          this.loadNotifications()      
        })  
        .catch( (error) =>{        
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
          this.notifications.loading = false
        })   
      },
      loadNotifications(reload = false){
        this.notifications.data = reload ? this.notifications.data : [] 
        this.notifications.loading = true               
        this.notifications.dialog = true
        
        this.$axios.get('not_notificaciones?fields=ins_id,ins_nombre,usr_id,usr_initials,usr_name,usr_color,usr_role&ins_id=' + this.insitutionId + '&order=not_date')
        .then( (respuesta) =>{  
          if( respuesta.data.num_res == 0 ){
            this.focusInput()
            this.notifications.loading = false   
            return   
          }

          this.notifications.data = respuesta.data.data  

          this.scrollDown()

          this.message = ''
          this.focusInput()

          this.notifications.loading = false      
        })  
        .catch( (error) =>{        
          let  message = '';
          if( error.status == 204 ){
            this.focusInput()
            return
          }
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
          this.notifications.loading = false
        })   
      },
      messageAdd(){
        if( this.message.length <= 1 ){
          return
        }

        this.notifications.loading = true 

        const postData = {
          not_insid: this.insitutionId,
          not_text: this.message,
          not_tipo: 'usuario',
          not_date: this.getMysqlDate()
        }

        this.$axios.post('not_notificaciones', postData)
        .then( (respuesta) =>{

          this.updateInstitutionAlert(1)

          this.loadNotifications(1)
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: 'Notificación añadida con éxito!' } )
        })
        .catch( (error) =>{
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
          this.notifications.loading = false
        }) 
      },
      updateInstitutionAlert(value){
        this.$axios.put('ins_instituciones/setAlert', { ins_id: this.insitutionId, ins_alerta: value })
        .then( (respuesta) =>{
          this.$emit('reload-data')
        })
      },
      sameUser(index){
        if( index == 0 ){
          return true
        }else if(index > 0 && this.notifications.data[index-1].usr_id != this.notifications.data[index].usr_id ){
          return true
        }else{
          return false
        }
      },
      scrollDown(){
        setTimeout( () => {
          if( this.notifications.data.length > 0 ){
            this.$vuetify.goTo('#last',{
              container: "#v-dialog-text"
            })
          }
        }, 200)   
      },
      focusInput() {
        if( this.$refs.message !== undefined ){
          this.$refs.message.focus();
        }        
      }
    },
    watch: {},
  }
</script>