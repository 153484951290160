<template>
  <div class="dashboard mb-16">
    <v-overlay 
      :value="loadingInstitutions"
    >
      <v-progress-circular
        indeterminate
        color="white"
      ></v-progress-circular>
    </v-overlay>

    <h1 class="mb-5">Buscador</h1>

    <v-row>
      <v-col cols="12" xl="3" lg="4" md="6" sm="8" xs="12">
        <v-expansion-panels>
          <v-expansion-panel> 
            <v-expansion-panel-header class="grey lighten-4">
              <div><v-icon class="mr-3 subtitle-1" color="primary">mdi-chart-areaspline</v-icon><span class="font-weight-medium">Estadísticas ({{institutionsCount}})</span></div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table 
                class="mt-3"
                dense
              >                
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Tipo
                      </th>
                      <th class="text-right">
                        Cantidad
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in stats"
                      :key="item.tipo"
                    >
                      <th>{{ item.tipo }}</th>
                      <td class="text-right">{{ item.count }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col>
      </v-col>
    </v-row>

    <v-card class="mb-5 mt-10 grey lighten-4">
      <v-card-title>Búscador</v-card-title>
      <v-card-subtitle>Filtra por diferentes criterios</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search.termino"
              label="Búsqueda por término"
              clearable
              filled
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="search.tipos"
              :items="tipos"
              item-text="tip_nombre"
              item-value="tip_id"
              label="Tipos"
              clearable
              filled              
              multiple
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <span class="font-weight-medium">Puntuación</span>
            <v-rating
              v-model="search.puntuacion"
              class=""
              background-color="grey"
              color="warning"
              size="25"
              length="5"
              clearable
              dense
              hover                
            ></v-rating>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-select
              v-model="search.paises"
              :items="paisesFiltered"
              item-text="ins_pais"
              item-value="ins_pais"
              label="Paises en español"
              clearable
              filled              
              multiple
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="search.estados"
              :items="estadosFiltered"
              item-text="ins_estado"
              item-value="ins_estado"
              label="Estados en idioma de origen"
              clearable
              filled              
              multiple
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="search.ciudades"
              :items="ciudadesFiltered"
              item-text="ins_ciudad"
              item-value="ins_ciudad"
              label="Ciudades en idioma de origen"
              clearable
              filled              
              multiple
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="search.etiquetas"
              :items="tagList"
              item-text="tag_nombre"
              item-value="tag_nombre"
              label="Etiquetas"
              clearable
              filled              
              multiple
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="insertadoFechaMenuRef"
              v-model="insertadoFechaMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="insertadoFechaText"                  
                  label="Franja de fechas de alta en la BD"
                  hint="Hay que especificar 2 fechas inicio y fin"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  filled
                  dense
                  readonly
                ></v-text-field>    
              </template>
              <v-date-picker
                v-model="search.insertadoFecha"
                locale="es"
                range
              >
                <v-spacer></v-spacer> 
                <v-btn
                  text
                  color="secondary"
                  @click="insertadoFechaMenu = false"
                >
                  Cancelar
                </v-btn>               
                <v-btn
                  text
                  color="primary"
                  @click="$refs.insertadoFechaMenuRef.save(search.insertadoFecha)"
                >
                  OK
                </v-btn>              
              </v-date-picker>
            </v-menu>        
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="modificadoFechaMenuRef"
              v-model="modificadoFechaMenu"
              :close-on-content-click="false"              
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="modificadoFechaText"                  
                  label="Franja de fechas de actualización en la BD"
                  hint="Hay que especificar 2 fechas inicio y fin"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  filled
                  dense
                  readonly
                ></v-text-field>    
              </template>
              <v-date-picker
                v-model="search.modificadoFecha"
                locale="es"
                range
              >
                <v-spacer></v-spacer> 
                <v-btn
                  text
                  color="secondary"
                  @click="modificadoFechaMenu = false"
                >
                  Cancelar
                </v-btn>               
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modificadoFechaMenuRef.save(search.modificadoFecha)"
                >
                  OK
                </v-btn>              
              </v-date-picker>
            </v-menu>        
          </v-col>
          <v-col cols="12" md="2">
            <v-checkbox
              v-model="search.notificaciones"
              label="Notificaciones"
              color="primary"
              :false-value="0"
              :true-value="1"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="2">
            <v-checkbox
              v-model="search.alerta"
              label="Alerta"
              color="primary"
              :false-value="0"
              :true-value="1"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="2" class="text-right">
            <v-btn
              @click="resetSearchForm"
              color="primary"   
              class="mt-4"   
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-5">
        <v-spacer></v-spacer>         
        
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <div id="searchResult"></div>

    <v-row  class="mt-15">
      <v-col md="8">
        <h2>
          Listado de instituciones 
          <span class="grey--text font-weight-light">
            ({{ institutionsCount }})
          </span>
        </h2>
        <div class="caption grey--text mb-5">
          <span class="black--text">Mostrando {{ startIndex + 1 }} a {{ stopIndex > searchResultCount ? searchResultCount : stopIndex }} de {{ searchResultCount }} resultados</span> (filtrado de un total de {{ institutionsCount }} instituciones)
        </div>  
      </v-col>
      <v-col md="4" class="text-end">
        <v-btn
          @click="loadInstitutions"
          class="mt-2 ml-2"
          color="primary lighten-3"
          height="36"
          elevation="1" 
          x-small
        >
          <v-icon color="black" small>mdi-table-refresh</v-icon><span class="black--text ml-1">Recargar listado</span>
        </v-btn>
      </v-col>
    </v-row>
      
    <v-row>
      <v-col>
        <v-pagination
          v-model="page"
          :length="pages"
          total-visible="9"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          class="mb-0"
        ></v-pagination>
      </v-col>
    </v-row>  

    <v-row v-if="loadingInstitutions">
      <v-col md="2" offset-md="5">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col
        v-for="(item, index) in searchResultShowed" :key="index"
        cols="12" lg="3" md="4" sm="6"
      >
        <v-card
          class="d-flex flex-column"
          height="100%"
        >
          <div class="px-5 pb-1 pt-6 grey lighten-3">            
            <v-img    
              v-if="item.ins_logo_offline_filename"        
              :lazy-src=" item.ins_logo_offline_filename ? getBaseUrl + getFilesUrl + 'logos/' + item.ins_logo_offline_filename : '' "            
              :src=" item.ins_logo_offline_filename ? getBaseUrl + getFilesUrl + 'logos/' + item.ins_logo_offline_filename : '' " 
              position="center center"             
              max-height="100"
              height="100"
              contain
            ></v-img>
            <div v-else class="text-center">
              <v-avatar              
                size="100"
                tile
              >
                <v-icon color="grey lighten-2">
                  mdi-file-hidden
                </v-icon>
              </v-avatar>
            </div>
            <v-btn
              @click=" loadNotifications(item.ins_id) " 
              class="float-right"
              icon small
            >
              <v-icon :color="setNotificationColor(item)" small>mdi-comment-alert-outline</v-icon>
            </v-btn> 
          </div>
          <v-card-text class="pb-0">
            <div class="text-caption"><span class="font-weight-bold">{{ item.ins_pais }}</span> <span v-if="item.ins_estado">/ {{ item.ins_estado }}</span> / {{ item.ins_ciudad }}</div>
          </v-card-text>
          <v-card-title class="pt-0">  
            {{ item.ins_nombre_ES ? item.ins_nombre_ES : item.ins_nombre }}            
          </v-card-title>
          <v-card-subtitle>
            <div><span class="font-weight-bold">{{ item.ins_iniciales ? item.ins_iniciales + ' - ' : "" }}</span> {{ item.ins_nombre }}</div>
            <div class="text-caption text-truncate">
              <v-row>
                <v-col md="10" sm="9" cols="6">
                  <div class="text-truncate pt-1">
                    <a :href="item.ins_url_web" target="_blank" rel="noopener noreferrer">{{ item.ins_url_web }}</a>
                  </div>                    
                </v-col>
                <v-col md="2" sm="9" cols="6" class="text-right">
                  <v-btn
                    :href="'https://translate.google.com/translate?sl=auto&tl=es&u=' + item.ins_url_web"                 
                    target="_blank"                    
                    class="teal lighten-5 secondary--text mr-1"
                    icon small link 
                  >
                    <v-icon small>mdi-google-translate</v-icon>
                  </v-btn> 
                </v-col>
              </v-row>                                   
            </div>
            <div class="font-weight-medium black--text pt-2">{{ item.tip_nombre }}</div>
            <v-rating
              :value="item.ins_rating"
              background-color="grey lighten-3"
              color="warning"
              size="16"
              length="5"
              dense
              hover                
              readonly
            ></v-rating>
          </v-card-subtitle>
          <v-card-text>
            <div class="pb-3">{{ item.ins_comentarios }}</div>
            <div class="pb-3">
              <v-chip
                class="mb-1 mr-2"
                color="grey lighten-3"
                text-color="teal lighten-2"
                small
                label
              >
                <v-icon left small>
                  mdi-database-import
                </v-icon>
                {{ item.ins_insert_date }}
              </v-chip>
              <v-chip
                v-if="item.ins_update_date"
                class="mb-1"
                color="grey lighten-3"
                text-color="teal lighten-2"
                small
                label
              >
                <v-icon left small>
                  mdi-database-edit
                </v-icon>
                {{ item.ins_update_date }}
              </v-chip>
            </div>
            <v-chip
              v-for="(tag, index) in item.ins_tags" :key="index"
              class="teal lighten-2 caption mr-2 mb-1"
              text-color="white"
              small
              label
            >
              {{ tag }}
            </v-chip>
            <v-spacer></v-spacer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
          v-model="page"
          :length="pages"
          total-visible="9"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        ></v-pagination>
      </v-col>
    </v-row>  

    <notifications-dialog ref="notificationsDialog" @reload-data="loadInstitutions"></notifications-dialog>  

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mixinGlobal } from '@/mixins/mixinGlobal'

import notificationsDialog from '@/components/NotificationsDialog.vue'

export default {
  name: "dashoard",
  components: { notificationsDialog },
  mixins: [ mixinGlobal ],
  data: () => ({
    loading: false,
    dialogConfirm: false,
    title: process.env.VUE_APP_TITLE,
    mode: process.env.NODE_ENV,
    url: process.env.VUE_APP_API_URL,
    tipos: [],
    search: {
      termino: '',
      tipos: [],
      puntuacion: 0,
      paises: [],
      estados: [],
      ciudades: [],
      etiquetas: [],
      modificadoFecha: [],
      insertadoFecha: [],
      notificaciones: 0,
      alerta: 0
    },
    insertadoFechaMenu: false,
    modificadoFechaMenu: false,
    tagList: [],
    picker: '',
    expanded: [],
    page: 1,
    loadingInstitutions: false,
  }),
  mounted () {
    //this.$store.dispatch('loadInstitutions')
    this.loadInstitutions()
    this.getTipos()
    this.getTags()
  },
  computed: {
    ...mapGetters(['getInstitutions', 'getBaseUrl', 'getFilesUrl']),
    institutionsApproved: function() {
      return this.getInstitutions.filter( ins => ins.ins_aprobado == 1 )
    },
    paisesFiltered: function (){
      let paises = []
      let raw = [...new Map(this.getInstitutions.map(item => [item['ins_pais'], item])).values()]
      raw.forEach( element => {
        paises.push(element.ins_pais)
      })
      return paises
    },
    estadosFiltered: function (){
      let estados = []
      let raw = [...new Map(this.getInstitutions.map(item => [item['ins_estado'], item])).values()]
      raw.forEach( element => {
        estados.push(element.ins_estado)
      })
      return estados
    },
    ciudadesFiltered: function (){
      let ciudades = []
      let raw = [...new Map(this.getInstitutions.map(item => [item['ins_ciudad'], item])).values()]
      raw.forEach( element => {
        ciudades.push(element.ins_ciudad)
      })
      return ciudades
    },
    stats: function(){
      let data = []
      if( this.institutionsApproved && this.tipos ){
        this.tipos.forEach(tipo => {
          let count = this.institutionsApproved.filter(function(institution){
              return institution.ins_tipid == tipo.tip_id
            }).length

          data.push({ "tipo": tipo.tip_nombre, "count": count })            
        })   
      }
      return data
    },
    insertadoFechaText:{
      get: function(){
        return this.search.insertadoFecha ? this.search.insertadoFecha.join(' ~ ') : ''
      },
      set: function(newValue){        
        this.search.insertadoFecha = []
      }
    },
    modificadoFechaText:{
      get: function(){
        return this.search.modificadoFecha ? this.search.modificadoFecha.join(' ~ ') : ''
      },
      set: function(newValue){        
        this.search.modificadoFecha = []
      }
    },
    filterTermino: function (){
      //return this.getInstitutions slice(0,2). .slice(0,10)
      return this.search.termino ? this.institutionsApproved.filter( item => 
              this.findInKeys(item, this.search.termino) 
            ) : this.institutionsApproved
    },
    filterTipo: function(){
      return this.search.tipos.length > 0 ? this.filterTermino.filter( item => this.search.tipos.includes(item.tip_id) ) : this.filterTermino
    },
    filterPuntuacion: function (){
      return this.search.puntuacion >= 1 ? this.filterTipo.filter( item => item.ins_rating == this.search.puntuacion ) : this.filterTipo
    },
    filterPais: function(){
      return this.search.paises.length > 0 ? this.filterPuntuacion.filter( item => this.search.paises.includes(item.ins_pais) ) : this.filterPuntuacion
    },
    filterEstado: function (){
      return this.search.estados.length > 0 ? this.filterPais.filter( item => this.search.estados.includes(item.ins_estado) ) : this.filterPais
    },
    filterCiudad: function (){
      return this.search.ciudades.length > 0 ? this.filterEstado.filter( item => this.search.ciudades.includes(item.ins_ciudad) ) : this.filterEstado
    },
    filterEtiquetas: function (){
      return this.search.etiquetas.length > 0 ? this.filterCiudad.filter( ins => 
        this.search.etiquetas.every( etq => 
          ins.ins_tags.includes(etq)
        )
      ) : this.filterCiudad 
    },
    filterAlta: function(){      
      return this.search.insertadoFecha.length == 2 ? this.filterEtiquetas.filter( item => 
              this.mysqlDateTimeToJsDate(item.ins_insert_date) >= this.mysqlDateTimeToJsDate(this.search.insertadoFecha[0]) && 
              this.mysqlDateTimeToJsDate(item.ins_insert_date) <= this.mysqlDateTimeToJsDate(this.search.insertadoFecha[1])
             ) : this.filterEtiquetas
    },
    filterModificado: function(){      
      return this.search.modificadoFecha.length == 2 ? this.filterAlta.filter( item => 
              this.mysqlDateTimeToJsDate(item.ins_update_date) >= this.mysqlDateTimeToJsDate(this.search.modificadoFecha[0]) && 
              this.mysqlDateTimeToJsDate(item.ins_update_date) <= this.mysqlDateTimeToJsDate(this.search.modificadoFecha[1])
             ) : this.filterAlta
    },
    filterNotificaciones: function(){  
      return this.search.notificaciones == 1 ? this.filterModificado.filter( item => item.not_notificaciones.length >= 1 ) : this.filterModificado
    },
    filterAlertas: function(){  
      return this.search.alerta == 1 ? this.filterNotificaciones.filter( item => item.ins_alerta == 1 ) : this.filterNotificaciones
    },
    searchResult: function(){
      return this.filterAlertas
    },
    searchResultCount: function(){
      return this.searchResult.length
    },
    searchResultShowed: function(){
      /* let startIndex = this.page == 1 ? 0 : (this.page-1) * 24
      let stopIndex = this.page * 24 */
      return this.searchResult.slice(this.startIndex, this.stopIndex)
    },
    institutionsCount: function(){
      return this.institutionsApproved.length
    },
    pages: function(){
      return Math.ceil(this.searchResultCount / 24)
    },
    startIndex: function(){
      return this.page == 1 ? 0 : (this.page-1) * 24
    },
    stopIndex: function(){
      return  this.page * 24
    }
  },
  methods: {
    ...mapActions(['loadInstitutions']),
    getTipos(){
      this.$axios.get('tip_tipos?order=tip_nombre')
      .then( (respuesta) =>{  
        this.tipos = respuesta.data.data          
      })  
      .catch( (error) =>{
        let  message = '';
        if (error.response.data.hasOwnProperty('message')) {
          message = error.response.data.message;
        }else{
          message = error
        }
        this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
      })    
    },
    getTags(){
      this.$axios.get('tag_tags?order=tag_nombre')
      .then( (respuesta) =>{  
        let tmp = respuesta.data.data 
        tmp.forEach(element => {
          this.tagList.push(element.tag_nombre)
        });         
      })  
      .catch( (error) =>{
        let  message = '';
        if (error.response.data.hasOwnProperty('message')) {
          message = error.response.data.message;
        }else{
          message = error
        }
        this.$store.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
      })    
    },
    loadInstitutions(){
      this.loadingInstitutions = true
      this.$store.dispatch('loadInstitutions')
      .then( () => {
        this.loadingInstitutions = false
      })
      .catch( () => {
        this.loadingInstitutions = false
      })
    },
    findInKeys(obj, needle){
      //console.log(obj)
      //console.log(needle)

      let found = false
      Object.keys(obj).forEach( key => {
        //console.log(key)
        String(obj[key]).toLowerCase().includes(needle.toLowerCase()) ? found = true : ''
      })
      return found ? true : false
    },
    showDialogConfirm(){
      this.dialogConfirm = true
    },
    onDialogConfirm(){
      this.dialogConfirm = false
    },
    scrollTo(id){
      this.$nextTick( () => {
        this.$vuetify.goTo(id)
      })
      
    },
    loadNotifications(insId){
      this.$refs.notificationsDialog.loadInstitutionNotifications(insId)
    },
    resetSearchForm(){
      this.search = {
        termino: '',
        tipos: [],
        puntuacion: 0,
        paises: [],
        estados: [],
        ciudades: [],
        etiquetas: [],
        modificadoFecha: [],
        insertadoFecha: [],
        notificaciones: 0,
        alerta: 0
      }
    },
    setNotificationColor(ins){
      if( ins.ins_alerta ){
        return 'red'
      }
      if( ins.not_notificaciones.length > 0 ){
        return 'primary'
      }else{
        return 'grey lighten-1'
      }
    },
},
  watch: {
    searchResultCount(newValue, oldValue) {
      this.page = 1
    },
    page(newValue, oldValue){
      newValue != oldValue ? this.scrollTo('#searchResult') : ''
    }
  },
}
</script>
<style scoped>
  .mb-xl{ margin-bottom: 80px; }
  .custom-table table td {
    padding: 16px 16px !important;
  }
</style>
